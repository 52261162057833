:root {
    ---primary-color: #BAA137;
    --secondary-color: #7E6227;
    --grey-color: #F5F5F5;
    --red-color: #ff0404;
    --hover-text-color: #e2e2e2;
    --aisle-text-color: #827D7D;
    --mens-bg: #FA9D00;
    --womens-bg: #CE2BF7;
    --checkout-bg: #10CD48;
    --costly-bg: #1FB6E9;
    --normal-bg: #D46A19;
    --green-text-color: #2B9D32;
    --placeholder-text-color: #c0c0c0;
    --active-card-color: #0d6efd;

    --white: #ffffff;
    --black: #181818;
    --bg-color: #f0f0f0;
}

@font-face {
    font-family: Poppins-Bold;
    src: url("../font/Poppins-Bold.ttf");
}

@font-face {
    font-family: Poppins-Medium;
    src: url("../font/Poppins-Medium.ttf");
}

@font-face {
    font-family: Poppins-Light;
    src: url("../font/Poppins-Light.ttf");
}

@font-face {
    font-family: Poppins-Semibold;
    src: url("../font/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: Poppins-regular;
    src: url("../font/Poppins-Regular.ttf");
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.fs-40 {
    font-size: 40px;
}

.f-bold {
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, Poppins-Bold;
}

.f-med {
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, Poppins-Medium;
}

.f-light {
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, Poppins-Light;
}

.f-sbold {
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, Poppins-Semibold;
}

.f-reg {
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, Poppins-regular;
}

.vision_dashboard .vision_dashboard_content {
    width: 100%;
    height: 100%;
}

/* left side sidebar content start */

.vision_dashboard .vision_dashboard_content .left_side {
    height: 100vh;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    background: linear-gradient(#BAA137, #886828);
    width: 22vw;
    position: fixed;
    top: 0;
}

.vision_dashboard .vision_dashboard_content .left_side_hide {
    left: -22vw;
}

.vision_dashboard .vision_dashboard_content .left_side_show {
    left: 0px;
}

.dropdown-menu-lg-start[data-bs-popper] {
    right: 0;
    left: auto;
}

.side_header {
    display: flex;
    justify-content: center;
    padding: 16px 0 5px 0;
    position: relative;
}

.arrow_left {
    display: none;
}

.side_footer {
    width: 300px;
    position: absolute;
    bottom: 0;
}

.profile {
    background-image: url(../img/white_cvi_logo.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 105px;
    height: 105px;
    border: none !important;
}

.profile_name {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    border: none;
    background-color: transparent;
    color: var(--black);
}

.product_name:hover {
    background-color: transparent;
}

.profile_logo {
    /* background-image: url(../img/profile.jpg);
    background-repeat: no-repeat;
    background-size: cover; */
    background: linear-gradient(#BAA137, #886828);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--white);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 20px;
}

.vision_dashboard .vision_dashboard_content .left_side .active {
    background-color: var(--grey-color);
    color: black;
    border-radius: 0;
    border-right: 4px solid var(--secondary-color);
    /* font-weight: 600; */
}

.side_content {
    max-height: 90vh;
    overflow: auto;
    padding-bottom: 80px;
    /* margin-top: 25px; */
}

.vision_dashboard .vision_dashboard_content .left_side ul li .nav-link {
    padding: 15px 0 20px 30px;
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, 'Poppins-Medium';
}

.vision_dashboard .vision_dashboard_content .left_side .side_footer {
    background-color: var(--grey-color);
}

.vision_dashboard .vision_dashboard_content .left_side .side_footer ul li .nav-link {
    padding: 15px 0 30px 30px;
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, 'Poppins-Medium';
    background-color: var(--grey-color);
}

.vision_dashboard .vision_dashboard_content .left_side .sub_heading {
    padding: 15px 0px 10px 39px;
    font-weight: 700;
    font-size: 12px;
    color: var(--white);
    letter-spacing: 4px;
}

.vision_dashboard .vision_dashboard_content .left_side ul li:hover {
    background-color: var(--hover-text-color);
}

/* left side sidebar content end */

/* top header start */

.top_header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    transition: all .1s ease-out;
}

.top_header_cover {
    left: 0px;
}

.top_header_mid {
    left: 22vw;
}

.logo_menu {
    width: 100%;
    padding: 15px 45px;
    box-shadow: rgb(0 0 0 / 16%) 5px 1px 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
}

.logo_img {
    width: 10%;
}

.arrow_mob {
    display: none;
}


/* top header end */

/* right side content start */

.vision_dashboard .vision_dashboard_content .right_side {
    height: 100vh;
    padding-top: 75px;
}

.vision_dashboard .vision_dashboard_content .right_side_cover {
    margin-left: 0px;
}

.vision_dashboard .vision_dashboard_content .right_side_mid {
    margin-left: 22vw;
}

.body_content {
    padding: 25px;
    background-color: #f8f8f8;
}

::placeholder {
    color: var(--placeholder-text-color);
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, Poppins-regular;
    font-size: 15px;
}


/* manage aisle end */

/* login start */

.login_card {
    width: 70%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    position: relative;
    background-color: #fff5;
    backdrop-filter: blur(7px);
}

.login_main_screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#BAA137, #886828);
}

.login_btn {
    background-color: var(--secondary-color) !important;
    color: var(--white) !important;
    padding: 8px 30px;
}

.login_btn:hover {
    background-color: var(--secondary-color) !important;
    color: var(--white) !important;
    opacity: .8;
}

/* login end */

/* webkit scrollbar start */

::-webkit-scrollbar {
    width: 6px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgb(151, 151, 151);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.body_header {
    background-color: var(--white);
    padding: 14px 20px;
    border-radius: 10px;
    border: 1px solid var(--bg-color);
    display: flex;
    justify-content: space-between;
}

.body_header:hover {
    box-shadow: rgb(0 0 0 / 9%) 2px 2px 3px 0px;
}

.list_form:hover {
    box-shadow: rgb(0 0 0 / 9%) 2px 2px 3px 0px;
}

.body_header .title {
    font-size: 22px;
    font-weight: 700;
    color: var(--secondary-color);
}

.body_header .title_active {
    color: var(--secondary-color);
}

.toggle_btn {
    display: none;
}

.add_btn {
    border: none;
    background-color: var(--secondary-color);
    color: var(--white);
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 8px 16px;
    border-radius: 10px;
}


.list_form {
    background-color: var(--white);
    padding: 20px;
    border-radius: 16px;
}

.table_heading {
    font-size: 16px;
    font-weight: 600;
}

form .form-control,
form .form-select {
    background-color: var(--bg-color);
    padding: 10px;
}

form .form-control:focus,
form .form-select:focus {
    box-shadow: none;
    border: 1px solid var(--bg-color);
    background-color: var(--bg-color);
}

form label {
    text-transform: uppercase;
    font-size: 14px;
}

.upload_img {
    display: flex;
    justify-content: center;
}

.upload_img .upload_img_box {
    background-color: #f0f0f0;
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    border-radius: 15px;
    border: none;
}

.upload_btn {
    background-color: var(---primary-color);
    color: var(--white);
    border: none;
    padding: 6px 0;
    border-radius: 10px;
}

.upload_btn:hover {
    background-color: var(---primary-color);
    opacity: .8;
}

/* table start */
.table_bg {
    background-color: var(--white);
    border: 1px solid var(--bg-color);
    padding: 20px;
    border-radius: 16px;
    width: 74vw;
    /* height: 90vh; */
    overflow: hidden;
}

.table_bg:hover {
    box-shadow: rgb(0 0 0 / 9%) 2px 2px 3px 0px;
}

.table .product_img {
    width: 45px;
    height: 45px;
    object-fit: contain;
    border: 1px solid var(--bg-color);
    vertical-align: middle;
}

.table_bg .table_body {
    width: 100%;
    overflow: auto;
}

.table thead th {
    min-width: 12em;
}

.table tbody td {
    vertical-align: middle;
}

.table,
th,
td {
    padding: 1rem;
}

.table thead th {
    background-color: var(---primary-color);
    padding: 14px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.status_td {
    background-color: #2ca7281f;
    color: #187d44;
    border-radius: 30px;
    padding: 6px 25px;
    text-align: center;
}

.inactive_td {
    background-color: #ffc4cd;
    color: #e00b2b;
    border-radius: 30px;
    padding: 6px 25px;
    text-align: center;
}

.delet_btn {
    background-color: #ffdfe4;
    padding: 5px;
    border-radius: 7px;
    border: none;
    display: flex;
    align-items: center;
}

.edit_btn {
    background-color: #ddfbe9;
    padding: 5px;
    border-radius: 7px;
    border: none;
    display: flex;
    align-items: center;
}

.form-check-input:checked {
    background-color: var(---primary-color);
    border-color: var(---primary-color);
}

.table th,
.table td {
    border: 1px solid #f0f0f0;
}

.table tbody tr:nth-child(even) {
    background-color: #f8f8f8;
}

.table .product_name {
    font-size: 14px;
    display: -webkit-box;
    max-width: 150px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product_td {
    display: flex;
    align-items: center;
    gap: 10px;
}

.action_td {
    display: flex;
    gap: 8px;
}

.form-switch .form-check-input {
    width: 36px;
    height: 20px;
}

.form-switch .form-check-input:focus {
    box-shadow: none;
}

.active>.page-link,
.page-link.active {
    z-index: 0;
    color: var(--bs-pagination-active-color);
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.page-link {
    color: var(--secondary-color);
}

/* offcnvas start */
.offcanvas {
    background: linear-gradient(#BAA137, #886828);
}

.offcanvas .offcanvas-header {
    border-bottom: 1px solid #f0f0f0;
}

.offcanvas .offcanvas-body {
    padding-left: 0;
    padding-right: 0;
}

.offcanvas ul li .nav-link {
    padding: 16px;
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, 'Poppins-Medium';
}

.offcanvas ul li .active {
    background-color: var(--grey-color);
}

.offcanvas ul li .nav-link p {
    font-size: 22px;
}

.offcanvas .sub_heading {
    padding: 16px;
    font-weight: 700;
    font-size: 18px;
    color: var(--white);
    letter-spacing: 4px;
}

.offcanvas ul li:hover {
    background-color: var(--hover-text-color);
}

/* dashboard start */
.dashboard .dashboard_right {
    background-color: var(--white);
    border: 1px solid var(--bg-color);
    padding: 20px;
    border-radius: 16px;
    /* overflow: hidden; */
}

.dashboard .dashboard_left {
    background-color: var(--white);
    border: 1px solid var(--bg-color);
    padding: 20px;
    border-radius: 16px;
    /* overflow: hidden; */
}

.dashboard .dashboard_right:hover {
    box-shadow: rgb(0 0 0 / 9%) 2px 2px 3px 0px;
}

.dashboard .dashboard_left:hover {
    box-shadow: rgb(0 0 0 / 9%) 2px 2px 3px 0px;
}

.card_text {
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, Poppins-SemiBold;
    font-size: 16px;
    color: #403e57;
}

.border_top {
    border-top: 1px solid #dcdcdc;
}

.text_B {
    font-family: Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif, Poppins-SemiBold;
    color: #403e57;
}

.icon_green {
    background-color: #2B9D32;
    border-radius: 50%;
    padding: 2px;
    width: 20px;
    height: 20px;
}

.icon_red {
    background-color: #e00b2b;
    border-radius: 50%;
    padding: 2px;
    width: 20px;
    height: 20px;
}

.trible_dot {
    border: none;
    background-color: transparent;
}

.trend_btn {
    border: none;
    background-color: transparent;
    padding: 5px;
}

.trend .active {
    border: none;
    border-bottom: 1.5px solid var(--black);
    background-color: transparent;
    font-weight: 700;
}

.body_footer p {
    color: #5e5d72;
    font-size: 14px;
}

.graph_chart {
    width: 550px;
}

.trend_chart {
    width: 400px;
}

.save_btn {
    background-color: var(--secondary-color);
    color: var(--white);
}

.save_btn:hover {
    background-color: var(--secondary-color);
    color: var(--white);
    opacity: .8;
}

.search_input {
    padding: 6px;
    background-color: #fff;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.search_icon {
    background-color: #fff;
    border: none;
    padding: 6px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.search_border {
    border: rgb(196, 195, 195) solid 1px;
    border-radius: 5px;
    height: 38px;
}

.search_input:focus-visible {
    outline: none;
}

.dropdown-item:active {
    background-color: var(---primary-color);
    color: var(--white);
}

.model-top {
    margin-top: 200px;
}

.css-qbdosj-Input {
    max-width: 200px !important;
    word-wrap: break-word !important;
    overflow-x: hidden !important;
    text-overflow: ellipsis !important;
}

.dropdown_wrap {
    width: 200px !important;
}

.captcha-image{
    height: 40px;
}
/* .regenerate-captcha-btn{
    width: 40px;
} */

/* dashboard end */
@media (max-width:1190px) {
    .customer_list {
        width: 100%;
    }
}


@media (max-width:768px) {

    /* left sidebar start  */
    .table_bg {
        background-color: var(--white);
        border: 1px solid var(--bg-color);
        padding: 20px;
        border-radius: 16px;
        width: 93vw;
        /* height: 90vh; */
        overflow: hidden;
    }

    .toggle_btn {
        display: block;
    }

    .login_card {
        width: 90%;
    }

    .graph_chart {
        width: 300px;
    }

    .trend_chart {
        width: 320px;
    }

    .vision_dashboard .vision_dashboard_content .left_side_show {
        left: 0px;
        z-index: 3;
    }

    .vision_dashboard .vision_dashboard_content .left_side_hide {
        left: -22vw;
    }

    .side_menu_mob {
        display: flex;
    }

    .arrow_left {
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;
    }

    /* left sidebar end  */


    /* top header start */

    .logo_menu {
        padding: 15px 25px;
    }

    .logo_img {
        width: 35%;
        margin-left: 15px;
    }

    .list_img {
        display: none;
    }

    .arrow_mob {
        display: block;
    }

    .red_notification {
        width: 10px;
        height: 10px;
    }

    .top_header_cover {
        left: 0px;
    }

    /* top header end */

    /* right side start */

    .vision_dashboard .vision_dashboard_content .right_side {
        margin-top: 0;
        padding-top: 0;
    }

    .vision_dashboard .vision_dashboard_content .right_side_cover {
        margin-left: 0px;
    }

    .body_content {
        padding: 15px 25px;
        background-color: #f8f8f8;
    }

    /* right side end */

    .aisle_list,
    .category_list {
        width: 100%;
    }

    .number,
    .active_column .number {
        display: none;
    }

    .active_text {
        font-size: 15px;
        font-family: Helvetica,
            Arial,
            Verdana,
            Tahoma,
            sans-serif, Poppins-bold;
        text-align: center;
    }

    .aisle_text {
        font-size: 15px;
        text-align: center;
    }

    .configure_img {
        width: 100%;
        margin-top: 30px;
        position: relative;
    }

    .dropdown_icon {
        padding: 7px;
    }

    .search_box {
        display: none;
    }

    .search_icon {
        display: block;
    }

    .search_box_mob {
        display: flex;
    }

    .sub_heading.sub_heading_reports {
        display: block;
        margin-top: 20px;
    }

    .top_btns {
        float: right;
    }

    .category_card {
        width: 28%;
    }
}

@media (min-width: 576px) {
    .popup_dialog {
        max-width: 825px !important;
    }

    .popup_dialog_configure {
        max-width: 1203px !important;
    }
}

.LogOutPopup {
    width: 300px;
    position: absolute !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    left: 40%;
    top: 40%;
}

.deleteModel {
    z-index: 1050 !important;
}

.logoutheader {
    height: 70px !important;
    border-bottom: none !important;
}

.modal-close {
    cursor: pointer;
    width: 38px;
    position: absolute;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    top: -20px;
    right: -8px;
    color: #d44b53;
}

.modal-close:hover {
    filter: brightness(80%);
}

.pointer-hover {
    cursor: pointer;
}

#pieChart {
    width: 400px !important;
    height: auto !important;
}

.icon_align {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon_color {
    background-color: #21c15911;
    border: 1px solid #21c158;
}

.icon_color_count {
    background-color: #f15cff11;
    border: 1px solid #F25CFF;
}

.icon_color_journey {
    background-color: #5ce1ff11;
    border: 1px solid #5CE2FF;
}

.card_design {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
    position: relative;
    background-color: #ffffff;
}

.card_icon {
    font-size: 22px;
    color: #21c158;
}

.card_icon_count {
    font-size: 22px;
    color: #F25CFF;
}

.card_icon_journey {
    font-size: 22px;
    color: #5CE2FF;
}

.graph_design {
    /* position: absolute; */
    bottom: 0;
    left: 0;
    width: 100%;
}

.card_align {
    padding: 16px;
}

.card_design img {
    height: 90px;
    width: 100%;
    object-fit: fill;
}